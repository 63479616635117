export const SERVICE_DATA = {
  sectionTitle: {
    title: "Solusi Terbaik Untuk Anda",
    text: "Solusi Simvent"
  },
  posts: [
    {
      extraClassName: "",
      icon: "piloz-writing",
      title: "Easy to edit",
      text: "Lorem ipsum is are many variations of pass majy."
    },
    {
      extraClassName: "bg-2",
      icon: "piloz-shield",
      title: "Fully secure",
      text: "Lorem ipsum is are many variations of pass majy."
    },
    {
      extraClassName: "bg-3",
      icon: "piloz-user",
      title: "Manage user",
      text: "Lorem ipsum is are many variations of pass majy."
    },
    {
      extraClassName: "bg-4",
      icon: "piloz-gear",
      title: "Free trial",
      text: "Lorem ipsum is are many variations of pass majy."
    }
  ]
};

export const CALL_TO_ACTION_DATA = {
  sectionTitle: {
    text: "Solusi Simvent",
    title: "Temukan Kemudahan Transaksi dan \n Pengelolaan Tiket"
  },
  paragraph:
    "Berbasis Software as a Service (SaaS) membuat Simvent mudah diakses kapanpun dan dimanapun.",
  posts: [
    {
      title: "Responsive \n Design",
      extraClassName: " ",
      icon: "piloz-devices"
    },
    {
      title: "Online \n Marketing",
      icon: "piloz-networking",
      extraClassName: "color-2"
    }
  ],
  button: {
    link: "#",
    label: "Coba Sekarang"
  }
};

import videoBG from "@images/resources/video-bg-1-1.jpg";

export const VIDEO_DATA = {
  title: "Watch Our Video",
  image: videoBG,
  videoID: "Kl5B6MBAntI"
};

import appFeature1 from "@images/resources/our app feature.png";

export const APP_FEATURE_DATA = {
  sectionTitle: {
    title: "Check in tiket dengan mudah \ndan cepat",
    text: "Solusi Simvent"
  },
  image: appFeature1,
  paragraph:
    "Check in tiket pengunjung kini jadi lebih mudah dan cepat hanya dengan scan barcode",
  options: [
    {
      label: "Refresing to get such a personal touch."
    },
    {
      label: "Duis aute irure dolor in reprehenderit in voluptate."
    },
    {
      label: "Velit esse cillum dolore eu fugiat nulla pariatur."
    }
  ],
  button: { link: "#", label: "Coba Sekarang" }
};

export const PRICING_DATA = {
  sectionTitle: {
    title: "Our Pricing Plans",
    text: "Choose Our Pricing"
  },
  monthly: [
    {
      count: "01",
      amount: "$20.00",
      name: "Basic Pack",
      extraClassName: "color-1",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "02",
      amount: "$30.00",
      name: "Basic Pack",
      extraClassName: "color-2",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "03",
      amount: "$60.00",
      name: "Basic Pack",
      extraClassName: "color-3",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    }
  ],
  yearly: [
    {
      count: "01",
      amount: "$40.00",
      name: "Premium Pack",
      extraClassName: "color-1",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "02",
      amount: "$80.00",
      name: "Premium Pack",
      extraClassName: "color-2",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "03",
      amount: "$100.00",
      name: "Premium Pack",
      extraClassName: "color-3",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    }
  ]
};

import testimonial1 from "@images/testimonials/testimonials-1-img-1.png";
import testimonial2 from "@images/testimonials/testimonials-1-img-2.png";
import testimonialShape from "@images/testimonials/testimonials-1-shape-1.png";
import testimonialShape2 from "@images/testimonials/testimonials-1-shape-2.png";

export const TESTIMONIALS_DATA = {
  sectionTitle: {
    text: "Our Testimonials",
    title: "What They Says"
  },
  posts: [
    {
      extraClassName: " ",
      shape: testimonialShape,
      image: testimonial1,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: " ",
      image: testimonial1,
      shape: testimonialShape,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: " ",
      image: testimonial1,
      shape: testimonialShape,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    }
  ]
};

import appshot1 from "@images/app-shots/1.png";
import appshot2 from "@images/app-shots/2.png";
import appshot3 from "@images/app-shots/3.png";
import appshot4 from "@images/app-shots/4.png";
import appshot5 from "@images/app-shots/5.png";
import appshot6 from "@images/app-shots/6.png";
import appshot7 from "@images/app-shots/7.png";
import appshot8 from "@images/app-shots/8.png";
import appshot9 from "@images/app-shots/9.png";
import appshot10 from "@images/app-shots/10.png";
import appshot11 from "@images/app-shots/11.png";
import appshot12 from "@images/app-shots/12.png";
import appshot13 from "@images/app-shots/13.png";
import appshot14 from "@images/app-shots/14.png";
import appshot15 from "@images/app-shots/15.png";

export const APP_SHOT_DATA = {
  sectionTitle: {
    title: "Temukan Solusi Terbaik Untuk Anda",
    text: "Solusi Simvent"
  },
  posts: [
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    },
    {
      image: appshot6
    },
    {
      image: appshot7
    },
    {
      image: appshot8
    },
    {
      image: appshot9
    },
    {
      image: appshot10
    },
    {
      image: appshot11
    },
    {
      image: appshot12
    },
    {
      image: appshot13
    },
    {
      image: appshot14
    },
    {
      image: appshot15
    }
  ]
};

export const FAQ_DATA = {
  sectionTitle: {
    text: "Frequently Asked Questions",
    title: "Have Any Question?"
  },
  posts: [
    {
      status: true,
      title: "Pre and post launch mobile app marketing pitfalls to avoid",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    },
    {
      status: false,
      title: "Boostup your application traffic is just a step away",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    },
    {
      status: false,
      title: "How to update application new features",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    },
    {
      status: false,
      title: "How to connect with the support to improve app experience",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    }
  ]
};

import blog1 from "@images/blog/news1.png";
import blog2 from "@images/blog/news2.png";
import blog3 from "@images/blog/news3.png";
import blog4 from "@images/blog/blog-2-4.jpg";
import blog5 from "@images/blog/blog-2-5.jpg";
import blog6 from "@images/blog/blog-2-6.jpg";

export const BLOG_DATA = {
  sectionTitle: {
    text: "From the Blog Posts",
    title: "News & Articles"
  },
  posts: [
    {
      image: blog1,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Leverage agile frame works to",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog2,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Launch New Mobile Marketing Pitfalls",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog3,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Bring to the table win-win survival",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog4,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Capitalize on low hanging fruit to",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog5,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Collaboratively admin istrate via ",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog6,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Efficiently unleash cross-media ",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    }
  ]
};
